import { PricingPlanBenefitsServer } from '@wix/ambassador-pricing-plan-benefits-server/http';
import {
  Balance,
  GetBalanceRequest,
} from '@wix/ambassador-pricing-plan-benefits-server/types';
import {
  BulkRequest,
  BulkResponse,
  GetActiveFeaturesResponse,
  GetServiceResponse,
  ServicesCatalogServer,
} from '@wix/ambassador-services-catalog-server/http';
import {
  mapCatalogResourceResponseToStaffMember,
  mapCatalogServiceResponseToService,
  mapResponseToBusinessInfo,
} from '@wix/bookings-uou-mappers';
import { BusinessInfoBase, CatalogData } from '@wix/bookings-uou-types';
import {
  QueryAvailabilityRequest,
  QueryAvailabilityResponse,
  Slot,
} from '@wix/ambassador-availability-calendar/types';
import { AvailabilityCalendar } from '@wix/ambassador-availability-calendar/http';
import { Experiments, IHttpClient } from '@wix/yoshi-flow-editor';
import {
  Booking,
  CheckoutApiConfig,
  createConfig,
  getBooking,
  reschedule,
} from '@wix/bookings-checkout-api';

interface CatalogDataFilter {
  servicesOptions?: {
    slug?: string;
    include: boolean;
  };
  resourcesOptions?: {
    slug?: string;
    include: boolean;
  };
}

export const CATALOG_SERVER_URL = '_api/services-catalog';
export const AVAILABILITY_SERVER_URL = '_api/availability-calendar';
export const CHECKOUT_SERVER_URL = '_api/checkout-server';
export const PRICING_PLAN__BENEFITS_SERVER_API = '_api/pricing-plan-benefits';

export class BookingsApi {
  private authorization: string;
  private experiments: Experiments;
  private bookingsCheckoutApiConfig: CheckoutApiConfig;
  private catalogServer: ReturnType<typeof ServicesCatalogServer>;
  private availabilityCalendarServer: ReturnType<typeof AvailabilityCalendar>;
  private pricingPlanBenefitsServer: ReturnType<
    typeof PricingPlanBenefitsServer
  >;

  constructor({
    authorization,
    baseUrl,
    experiments,
    httpClient,
  }: {
    authorization: string;
    baseUrl: string;
    experiments: Experiments;
    httpClient: IHttpClient;
  }) {
    this.authorization = authorization;
    this.experiments = experiments;
    this.bookingsCheckoutApiConfig = createConfig({
      getAuthorization: () => authorization,
      getBaseUrl: () => baseUrl,
      experiments: experiments as any,
      httpClient: httpClient as any,
    });

    this.catalogServer = ServicesCatalogServer(
      `${baseUrl}${CATALOG_SERVER_URL}`,
    );
    this.availabilityCalendarServer = AvailabilityCalendar(
      `${baseUrl}${AVAILABILITY_SERVER_URL}`,
    );
    this.pricingPlanBenefitsServer = PricingPlanBenefitsServer(
      `${baseUrl}${PRICING_PLAN__BENEFITS_SERVER_API}`,
    );
  }

  async getCatalogData({
    servicesOptions,
    resourcesOptions,
  }: CatalogDataFilter): Promise<
    CatalogData & { seoData?: GetServiceResponse[] }
  > {
    const servicesCatalogService = this.catalogServer.Bulk();

    const bulkRequest: BulkRequest = this.createBulkRequest({
      servicesOptions,
      resourcesOptions,
    });
    const catalogData: BulkResponse = await servicesCatalogService({
      Authorization: this.authorization,
    }).get(bulkRequest);

    const services = servicesOptions?.include
      ? catalogData.responseServices!.services!.map((service) =>
          mapCatalogServiceResponseToService(service),
        )
      : [];
    const businessInfo: BusinessInfoBase = mapResponseToBusinessInfo(
      catalogData.responseBusiness!,
    );
    const activeFeatures: GetActiveFeaturesResponse =
      catalogData.responseBusiness!.activeFeatures!;

    const staffMembers = catalogData.responseResources?.resources?.map(
      mapCatalogResourceResponseToStaffMember,
    );

    return {
      services,
      businessInfo,
      activeFeatures,
      staffMembers,
      seoData: catalogData.responseServices?.services || [],
    };
  }

  async getSlotsAvailability(
    queryAvailabilityRequest: QueryAvailabilityRequest,
  ): Promise<QueryAvailabilityResponse> {
    const availabilityCalendarService =
      this.availabilityCalendarServer.AvailabilityCalendar();

    const availability: QueryAvailabilityResponse =
      await availabilityCalendarService({
        Authorization: this.authorization,
        'X-Time-Budget': 30000,
      }).queryAvailability(queryAvailabilityRequest);

    return availability;
  }

  async getBookingDetails(bookingId: string) {
    return getBooking({ bookingId }, this.bookingsCheckoutApiConfig);
  }

  async rescheduleBooking({ booking, slot }: { booking: Booking; slot: Slot }) {
    return reschedule({ booking, slot }, this.bookingsCheckoutApiConfig);
  }

  private createBulkRequest({
    servicesOptions,
    resourcesOptions,
  }: CatalogDataFilter): BulkRequest {
    const isFilterServicesByUouHiddenEnabled = this.experiments.enabled(
      'specs.bookings.filterServicesByUouHidden',
    );
    return {
      requestServices: servicesOptions?.include
        ? {
            includeDeleted: false,
            query: {
              fieldsets: [],
              filter: isFilterServicesByUouHiddenEnabled
                ? JSON.stringify({
                    'slugs.name': servicesOptions.slug
                      ? servicesOptions.slug
                      : undefined,
                    'service.customProperties.uouHidden': 'false',
                  })
                : servicesOptions.slug
                ? `{"slugs.name":"${servicesOptions.slug}"}`
                : null,
              paging: {
                limit: 500,
              },
              fields: [],
              sort: [],
            },
          }
        : undefined,
      requestBusiness: {
        suppressNotFoundError: false,
      },
      requestListResources: resourcesOptions?.include
        ? {
            query: {
              fieldsets: [],
              filter: resourcesOptions.slug
                ? `{"slugs.name": "${resourcesOptions.slug}"}`
                : null,
              paging: {
                limit: 500,
              },
              fields: [],
              sort: [],
            },
          }
        : undefined,
    };
  }

  async getPurchasedPricingPlans({
    contactId,
    authorization,
  }: {
    contactId: string;
    authorization: string;
  }): Promise<Balance[]> {
    const balanceRequest: GetBalanceRequest = {
      contactId,
    };
    const benefits = await this.pricingPlanBenefitsServer
      .MemberBenefits()({ Authorization: authorization })
      .getBalance(balanceRequest);
    return benefits.balanceItems || [];
  }
}
